import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import type { UploadFile } from 'antd';
import ImgCrop from 'antd-img-crop';
import './index.css'
import { RcFile, UploadListType } from 'antd/es/upload/interface';

interface IProps {
    setFileList?: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
    fileList?: UploadFile[];
    imgCropAspect?: [number, number];
    maxImg?: number;
    listType?: UploadListType;
    imgListGap?: string;
    uploadIconText?: string;
    isRemoveIcon?: boolean
    onChange?: Function;
    sizeLimit?: number
    children?: ReactNode
    style?: React.CSSProperties
}
interface CustomCSSProperties extends CSSProperties {
    '--img-crop-aspect-0'?: string;
    '--img-crop-aspect-1'?: string;
    '--img-list-gap'?: string;
    '--display-anticon-delete-icon'?: string;
}

const UploadCropCustom = ({ setFileList, fileList = [], imgCropAspect, sizeLimit = 2, maxImg = 1, listType = 'picture-card', imgListGap = '24px', uploadIconText, isRemoveIcon = true, onChange, children, style }: IProps) => {
    const [fileListCh, setFileListCh] = useState<UploadFile[]>(fileList);
    const isRcFile = (file: string | RcFile | Blob): file is RcFile => {
        return typeof file === 'object' && file && 'name' in file && 'size' in file;
    };
    const customRequest = async (file: string | RcFile | Blob) => {
        if (isRcFile(file)) {
            if (!file) return;
            const uploadCheck = beforeUpload(file)
            if (!uploadCheck) return;
            const uploadFile = {
                ...file,
                originFileObj: file,
            }
            setFileListCh(prevFileList => [...prevFileList, uploadFile]);
            setFileList?.(prevFileList => [...prevFileList, uploadFile]);
        } else {
            message.warning("전달된 파일이 RcFile이 아닙니다.");
        }
    };
    const onPreview = async (file: UploadFile) => {
        try {
            let src: string | undefined = file.url || file.thumbUrl;
            if (!src) {
                src = await new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();
                    if (file.originFileObj) {
                        reader.readAsDataURL(file.originFileObj);
                        reader.onload = () => {
                            if (typeof reader.result === 'string') {
                                resolve(reader.result);
                            } else {
                                reject('파일 변환 실패: 결과가 문자열이 아님');
                            }
                        };
                        reader.onerror = () => reject('파일 변환 실패: 파일 읽기 에러');
                    } else {
                        reject('파일이 없습니다.');
                    }
                });
            }
            if (!src) {
                message.error('미리보기를 위한 이미지 소스를 찾을 수 없습니다.');
                return;
            }

            const image = new Image();
            image.src = src;
            image.style.display = 'block';
            image.style.margin = 'auto';

            const imgWindow = window.open('', '_blank');
            if (imgWindow) {
                const bodyStyle = imgWindow.document.body.style;
                bodyStyle.display = 'flex';
                bodyStyle.justifyContent = 'center';
                bodyStyle.alignItems = 'center';
                bodyStyle.height = '100vh';
                imgWindow.document.body.appendChild(image);
            } else {
                message.error('새 창을 열 수 없습니다.');
            }
        } catch (error) {
            message.error(typeof error === 'string' ? error : '미리보기 처리 중 오류 발생');
        }
    };

    const cropStyle = (): CustomCSSProperties => {
        const imgCropAspect0 = imgCropAspect?.[0] ? imgCropAspect?.[0] / 6 + "px" : "72px"
        const imgCropAspect1 = imgCropAspect?.[1] ? imgCropAspect?.[1] / 6 + "px" : "72px"
        return {
            '--img-crop-aspect-0': imgCropAspect0,
            '--img-crop-aspect-1': imgCropAspect1,
            '--img-list-gap': imgListGap,
            '--display-anticon-delete-icon': isRemoveIcon ? 'inline-flex' : 'none',
            ...style
        }
    }
    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('.jpg, .jpeg, .png 파일만 지원 합니다.');
        }
        const isLt2M = file.size / 1024 / 1024 < sizeLimit;
        if (!isLt2M) {
            message.error(`이미지 크기는 ${sizeLimit}MB보다 작아야합니다.`);
        }
        return isJpgOrPng && isLt2M;
    };

    const onRemove = (uploadFile: UploadFile<any>) => {
        setFileListCh(curr => curr?.filter(c => c.uid !== uploadFile.uid));
        setFileList?.(curr => curr?.filter(c => c.uid !== uploadFile.uid))
    }

    useEffect(() => {
        onChange?.(fileListCh)
    }, [fileListCh])

    return (
        <div style={cropStyle()}>
            {imgCropAspect ? <ImgCrop aspect={imgCropAspect[0] / imgCropAspect[1]} rotationSlider>
                <Upload
                    customRequest={(e) => customRequest(e.file)}
                    listType={listType}
                    fileList={fileListCh}
                    maxCount={maxImg}
                    onPreview={e => onPreview(e)}
                    onRemove={e => isRemoveIcon && onRemove(e)}
                >

                    {fileListCh.length < maxImg && (uploadIconText ? uploadIconText : " + Upload")}
                </Upload>
            </ImgCrop>
                : <Upload
                    customRequest={(e) => { customRequest(e.file); }}
                    listType={listType}
                    fileList={fileListCh}
                    maxCount={maxImg}
                    onPreview={e => onPreview(e)}
                    onRemove={e => isRemoveIcon && onRemove(e)}
                >
                    {fileListCh.length < maxImg && (uploadIconText ? uploadIconText : " + Upload")}
                </Upload>}
            {children}
        </div>
    );
};

export default UploadCropCustom;