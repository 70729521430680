import { Breadcrumb } from 'antd';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
interface IBreadCrumbItem {
    title: ReactNode | string;
}

const deprecatedBreadCrumbNameMap: Record<string, Array<IBreadCrumbItem>> = {
    '/': [{ title: '일정' }],
    '/schedule': [{ title: '일정' }],
    '/week': [{ title: '웰니스 Week' }],
    '/week/create': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week 생성' }],
    '/week/detail': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week 상세' }],
    '/item': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week Item' }],
    '/item/create': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: <Link to={'/item'}>웰니스 Week Item</Link> }, { title: '웰니스 Week Item 생성' }],
    '/item/detail': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: <Link to={'/item'}>웰니스 Week Item</Link> }, { title: '웰니스 Week Item 수정' }],
    '/reservation': [{ title: '예약' }],
    '/reservation/create': [{ title: <Link to={'/reservation'}>예약</Link> }, { title: '예약 생성' }],
    '/check-in/history': [{ title: '출석 내역' }],
    '/yoga-class': [{ title: '수업' }],
    '/yoga-lecture/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '수업 상세' }],
    '/yoga-lecture/update/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '수업 수정' }],
    '/yoga-class/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '그룹 수업 상세' }],
    '/yoga-class/update/detail': [{ title: <Link to={'/yoga-class'}>수업</Link> }, { title: '그룹 수업 수정' }],
    '/member': [{ title: '회원' }],
    '/member/create': [{ title: <Link to={'/member'}>회원</Link> }, { title: '회원 등록' },],
    '/member/detail': [{ title: <Link to={'/member'}>회원</Link> }, { title: '회원 상세' },],
    '/teacher': [{ title: '코치' }],
    '/teacher/create': [{ title: <Link to={'/teacher'}>코치</Link> }, { title: '코치 등록' }],
    '/teacher/detail': [{ title: <Link to={'/teacher'}>코치</Link> }, { title: '코치 수정' }],
    '/ticket': [{ title: '정기권' }],
    '/ticket/create': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 등록' }],
    '/ticket/detail': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 상세' }],
    '/ticket/update/detail': [{ title: <Link to={'/ticket'}>정기권</Link> }, { title: '정기권 수정' }],
    '/coupon': [{ title: '쿠폰' }],
    '/coupon/create': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '쿠폰 등록' }],
    '/coupon/detail': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '쿠폰 수정' }],
    '/yoga-coupon/detail': [{ title: <Link to={'/coupon'}>쿠폰</Link> }, { title: '요가 쿠폰 수정' }],
    '/notification/create': [{ title: '알림' }, { title: '푸시 알림 발송' }],
    '/notification': [{ title: '알림' }, { title: '발송 완료함' }],
    '/notification/push/detail': [{ title: '알림' }, { title: <Link to={'/notification'}>발송 완료함</Link> }, { title: '발송 완료 상세' }],
    '/notification/reservation': [{ title: '알림' }, { title: '예약 발송함' }],
    '/notification/reservation/detail': [{ title: '알림' }, { title: <Link to={'/notification/reservation'}>예약 발송함</Link> }, { title: '예약 발송 상세' }],
    '/notification/temp': [{ title: '알림' }, { title: '임시 저장함' }],
    '/notification/temp/detail': [{ title: '알림' }, { title: <Link to={'/notification/temp'}>임시 저장함</Link> }, { title: '임시 저장 상세' }],
    '/company': [{ title: '회사' }],
    '/company/detail/detail': [{ title: <Link to={'/company'}>회사</Link> }, { title: '회사 상세' }],
    '/setting/basic': [{ title: '설정' }, { title: '기본 정보' }],
    '/setting/detail': [{ title: '설정' }, { title: '상세 정보' }],
    '/setting/image-management': [{ title: '설정' }, { title: '요가원 사진 등록' }],
    '/setting/schedule': [{ title: '설정' }, { title: '요가원 시간표' }],
    '/setting/notice-popup': [{ title: '설정' }, { title: '공지사항' }],
    '/setting/notice-popup/create': [{ title: '설정' }, { title: <Link to={'/setting/notice-popup'}>공지사항</Link> }, { title: '공지사항 등록' }],
    '/setting/notice-popup/detail': [{ title: '설정' }, { title: <Link to={'/setting/notice-popup'}>공지사항</Link> }, { title: '공지사항 상세' }],
    '/setting/event': [{ title: '설정' }, { title: '이벤트' }],
    '/setting/event/create': [{ title: '설정' }, { title: <Link to={'/setting/event'}>이벤트</Link> }, { title: '이벤트 등록' }],
    '/setting/event/detail': [{ title: '설정' }, { title: <Link to={'/setting/event'}>이벤트</Link> }, { title: '이벤트 상세' }],
    '/setting/banner': [{ title: '설정' }, { title: '배너' }],
    '/setting/banner/create': [{ title: '설정' }, { title: <Link to={'/setting/banner'}>배너</Link> }, { title: '배너 등록' }],
    '/setting/banner/detail': [{ title: '설정' }, { title: <Link to={'/setting/banner'}>배너</Link> }, { title: '배너 수정' }],
    '/setting/schedule-info': [{ title: '설정' }, { title: '앱 다운로드' }],
    '/setting/schedule-info/create': [{ title: '설정' }, { title: <Link to={'/setting/schedule-info'}>앱 다운로드</Link> }, { title: '앱 다운로드 생성' }],
    '/setting/schedule-info/detail': [{ title: '설정' }, { title: <Link to={'/setting/schedule-info'}>앱 다운로드</Link> }, { title: '앱 다운로드 상세' }],
    '/revenue': [{ title: '매출' }],
    '/data-escalation': [{ title: '데이터 이관' }],
    '/check-in': [{ title: '출석' }],
    '/check-in/barcode/1/detail': [{ title: '바코드 출석' }],
    '/login': [{ title: '로그인' }],
    '/member-ship': [{ title: '멤버십' }],
}

const breadCrumbNameMap: Record<string, Array<IBreadCrumbItem>> = {
    '/': [{ title: '일정' }],
    '/schedule': [{ title: '일정' }],
    '/week': [{ title: '웰니스 Week' }],
    '/week/create': [
        { title: <Link to={'/week'}>웰니스 Week</Link> },
        { title: '웰니스 Week 생성' },
    ],
    '/week/detail': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week 상세' }],
    '/item': [{ title: <Link to={'/week'}>웰니스 Week</Link> }, { title: '웰니스 Week Item' }],
    '/item/create': [
        { title: <Link to={'/week'}>웰니스 Week</Link> },
        { title: <Link to={'/item'}>웰니스 Week Item</Link> },
        { title: '웰니스 Week Item 생성' },
    ],
    '/item/detail': [ // 추가됨
        { title: <Link to={'/week'}>웰니스 Week</Link> },
        { title: <Link to={'/item'}>웰니스 Week Item</Link> },
        { title: '웰니스 Week Item 수정' },
    ],
    '/reservation': [{ title: '예약' }],
    '/reservation/create': [
        { title: <Link to={'/reservation'}>예약</Link> },
        { title: '예약 생성' },
    ],
    '/reservation/detail': [ // 추가됨
        { title: <Link to={'/reservation'}>예약</Link> },
        { title: '예약 상세' },
    ],
    '/check-in/history': [{ title: '출석 내역' }],
    // Wellness Program 관련 추가
    '/wellness-program': [ // 추가됨
        { title: '웰니스프로그램' },
    ],
    '/wellness-program/program': [ // 추가됨
        { title: '웰니스프로그램' },
        { title: '프로그램' },
    ],
    '/wellness-program/program/create': [ // 추가됨
        { title: '웰니스프로그램' },
        { title: <Link to={'/wellness-program/program'}>프로그램</Link> },
        { title: '프로그램 생성' },
    ],
    '/wellness-program/program/detail': [ // 추가됨
        { title: '웰니스프로그램' },
        { title: <Link to={'/wellness-program/program'}>프로그램</Link> },
        { title: '프로그램 상세' },
    ],
    '/wellness-program/program/detail/apply': [ // 추가됨
        { title: '웰니스프로그램' },
        { title: <Link to={'/wellness-program/program'}>프로그램</Link> },
        { title: '프로그램 신청' },
    ],
    // Wellness Class / Lecture 관련 추가
    '/wellness-class': [{ title: '웰니스수업' }],
    '/wellness-class/create': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스수업 생성' },
    ],
    '/wellness-class/detail': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스수업 상세' },
    ],
    '/wellness-class/update/detail': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스수업 수정' },
    ],
    '/wellness-lecture/create': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스강의 생성' },
    ],
    '/wellness-lecture/detail': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스강의 상세' },
    ],
    '/wellness-lecture/update/detail': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '웰니스강의 수정' },
    ],
    '/yoga-program': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: '요가프로그램' },
    ],
    '/yoga-program/create': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: <Link to={'/yoga-program'}>요가프로그램</Link> },
        { title: '요가프로그램 생성' },
    ],
    '/yoga-program/detail': [ // 추가됨
        { title: <Link to={'/wellness-class'}>웰니스수업</Link> },
        { title: <Link to={'/yoga-program'}>요가프로그램</Link> },
        { title: '요가프로그램 상세' },
    ],
    '/yoga-lecture/detail': [ // 추가됨
        { title: <Link to={'/yoga-class'}>수업</Link> },
        { title: '요가강의 상세' },
    ],
    '/yoga-lecture/update/detail': [ // 추가됨
        { title: <Link to={'/yoga-class'}>수업</Link> },
        { title: '요가강의 수정' },
    ],
    '/member': [{ title: '회원' }],
    '/member/create': [
        { title: <Link to={'/member'}>회원</Link> },
        { title: '회원 등록' },
    ],
    '/member/detail': [ // 추가됨
        { title: <Link to={'/member'}>회원</Link> },
        { title: '회원 상세' },
    ],
    '/teacher': [{ title: '코치' }],
    '/teacher/create': [
        { title: <Link to={'/teacher'}>코치</Link> },
        { title: '코치 등록' },
    ],
    '/teacher/detail': [ // 추가됨
        { title: <Link to={'/teacher'}>코치</Link> },
        { title: '코치 상세' },
    ],
    // Wellness Ticket 관련 추가
    '/wellness-ticket': [ // 추가됨
        { title: '정기권' },
    ],
    '/wellness-ticket/create': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: '정기권 등록' },
    ],
    '/wellness-ticket/detail': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: '정기권 상세' },
    ],
    '/wellness-ticket/update/detail': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: '정기권 수정' },
    ],
    '/wellness-ticket-group': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: '정기권 그룹' },
    ],
    '/wellness-ticket-group/create': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: <Link to={'/wellness-ticket-group'}>정기권 그룹</Link> },
        { title: '정기권 그룹 등록' },
    ],
    '/wellness-ticket-group/update/detail': [ // 추가됨
        { title: <Link to={'/wellness-ticket'}>정기권</Link> },
        { title: <Link to={'/wellness-ticket-group'}>정기권 그룹</Link> },
        { title: '정기권 그룹 수정' },
    ],
    '/coupon': [{ title: '쿠폰' }],
    '/coupon/create': [
        { title: <Link to={'/coupon'}>쿠폰</Link> },
        { title: '쿠폰 등록' },
    ],
    '/coupon/detail': [ // 추가됨 (원래는 coupon/detail과 동일한 역할일 수 있음)
        { title: <Link to={'/coupon'}>쿠폰</Link> },
        { title: '쿠폰 수정' },
    ],
    '/yoga-coupon/detail': [ // 추가됨
        { title: <Link to={'/coupon'}>쿠폰</Link> },
        { title: '쿠폰 수정' },
    ],
    '/notification': [{ title: '알림' }, { title: '발송 완료함' }],
    '/notification/create': [
        { title: '알림' },
        { title: '푸시 알림 발송' },
    ],
    '/notification/reservation': [{ title: '알림' }, { title: '예약 발송함' }],
    '/notification/reservation/detail': [ // 추가됨 (reservation/detail과 유사)
        { title: <Link to={'/notification/reservation'}>예약 발송함</Link> },
        { title: '예약 발송 상세' },
    ],
    '/notification/temp': [{ title: '알림' }, { title: '임시 저장함' }],
    '/notification/temp/detail': [ // 추가됨 (temp/detail과 유사)
        { title: <Link to={'/notification/temp'}>임시 저장함</Link> },
        { title: '임시 저장 상세' },
    ],
    '/notification/push/detail': [ // 추가됨 (push/detail과 유사)
        { title: '알림' },
        { title: <Link to={'/notification'}>발송 완료함</Link> },
        { title: '푸시 알림 상세' },
    ],
    '/company': [{ title: '회사' }],
    '/company/detail/detail': [ // 추가됨
        { title: <Link to={'/company'}>회사</Link> },
        { title: '회사 상세' },
    ],
    '/setting/notice-popup': [{ title: '설정' }, { title: '공지사항' }],
    '/setting/notice-popup/create': [
        { title: '설정' },
        { title: <Link to={'/setting/notice-popup'}>공지사항</Link> },
        { title: '공지사항 등록' },
    ],
    '/setting/notice-popup/detail': [ // 추가됨 (notice-popup/detail과 동일한 역할)
        { title: <Link to={'/setting/notice-popup'}>공지사항</Link> },
        { title: '공지사항 상세' },
    ],
    '/setting/banner': [{ title: '설정' }, { title: '배너' }],
    '/setting/banner/create': [
        { title: '설정' },
        { title: <Link to={'/setting/banner'}>배너</Link> },
        { title: '배너 등록' },
    ],
    '/setting/banner/detail': [ // 추가됨 (banner/detail과 동일한 역할)
        { title: '설정' },
        { title: <Link to={'/setting/banner'}>배너</Link> },
        { title: '배너 수정' },
    ],
    '/setting/basic': [{ title: '설정' }, { title: '기본 정보' }],
    '/setting/detail': [{ title: '설정' }, { title: '상세 정보' }],
    '/setting/image-management': [{ title: '설정' }, { title: '요가원 사진 등록' }],
    '/setting/schedule': [{ title: '설정' }, { title: '요가원 시간표' }],
    '/setting/event': [{ title: '설정' }, { title: '이벤트' }],
    '/setting/event/create': [
        { title: '설정' },
        { title: <Link to={'/setting/event'}>이벤트</Link> },
        { title: '이벤트 등록' },
    ],
    '/setting/event/detail': [ // 추가됨 (event/detail과 동일한 역할)
        { title: '설정' },
        { title: <Link to={'/setting/event'}>이벤트</Link> },
        { title: '이벤트 상세' },
    ],
    '/setting/schedule-info': [{ title: '설정' }, { title: '앱 다운로드' }],
    '/setting/schedule-info/create': [
        { title: '설정' },
        { title: <Link to={'/setting/schedule-info'}>앱 다운로드</Link> },
        { title: '앱 다운로드 생성' },
    ],
    '/setting/schedule-info/detail': [ // 추가됨 (schedule-info/detail과 동일한 역할)
        { title: '설정' },
        { title: <Link to={'/setting/schedule-info'}>앱 다운로드</Link> },
        { title: '앱 다운로드 상세' },
    ],
    '/revenue': [{ title: '매출' }],
    '/data-escalation': [{ title: '데이터 이관' }],
    '/check-in': [{ title: '출석' }],
    '/check-in/barcode/:companyId/:yogaCenterId': [ // 추가됨 (동적 라우트)
        { title: '바코드 출석' },
    ],
    '/check-in/barcode/1/detail': [{ title: '바코드 출석' }],
    '/login': [{ title: '로그인' }],
    '/member-ship': [{ title: '멤버십' }],
    '/member-ship/create': [ // 추가됨
        { title: <Link to={'/member-ship'}>멤버십</Link> },
        { title: '멤버십 등록' },
    ],
    '/member-ship/detail': [ // 추가됨
        { title: <Link to={'/member-ship'}>멤버십</Link> },
        { title: '멤버십 수정' },
    ],
    // Report 관련 추가
    '/report/member/daily': [ // 추가됨
        { title: '통계' },
        { title: '회원 - 일별 분석' },
    ],
    '/report/member/time': [ // 추가됨
        { title: '통계' },
        { title: '회원 - 시간별 분석' },
    ],
    '/report/sales/daily': [ // 추가됨
        { title: '통계' },
        { title: '매출 - 일별 분석' },
    ],
    '/report/sales/weekly': [ // 추가됨
        { title: '통계' },
        { title: '매출 - 주별 분석' },
    ],
    '/report/sales/monthly': [ // 추가됨
        { title: '통계' },
        { title: '매출 - 월별 분석' },
    ],
    '/report/ticket/sold/rank': [ // 추가됨
        { title: '통계' },
        { title: '정기권 - 판매 순위' },
    ],
    '/report/ticket/retention/rank': [ // 추가됨
        { title: '통계' },
        { title: '정기권 - 보유 현황' },
    ],
    '/report/ticket/convention': [ // 추가됨
        { title: '통계' },
        { title: '정기권 - 구매 전환' },
    ],
    '/report/reservation/center': [ // 추가됨
        { title: '통계' },
        { title: '예약 - 센터별' },
    ],
    '/report/reservation/daily': [ // 추가됨
        { title: '통계' },
        { title: '예약 - 일별' },
    ],
    '/report/reservation/weekly': [ // 추가됨
        { title: '통계' },
        { title: '예약 - 주별' },
    ],
    '/report/reservation/monthly': [ // 추가됨
        { title: '통계' },
        { title: '예약 - 월별' },
    ],
    '/report/reservation/ticket': [ // 추가됨
        { title: '통계' },
        { title: '예약 - 정기권별' },
    ],
    '/report/teacher/reservation': [ // 추가됨
        { title: '통계' },
        { title: '코치 - 예약·출석별' },
    ],
    '/report/teacher/time': [ // 추가됨
        { title: '통계' },
        { title: '코치 - 시간별' },
    ],
    '/report/teacher/daily': [ // 추가됨
        { title: '통계' },
        { title: '코치 - 일별' },
    ],
    '/report/teacher/weekly': [ // 추가됨
        { title: '통계' },
        { title: '코치 - 주별' },
    ],
};

const PageHistory = () => {
    const location = useLocation();
    const pathSnippets = location.pathname;
    const regex = /(\d+)/;
    const checkDynamicPath = ((path: string) => {
        if (regex.test(path)) return path.replace(regex, "detail");
        return path;
    })

    return (
        <Breadcrumb style={{ margin: '16px 0' }} items={breadCrumbNameMap[checkDynamicPath(pathSnippets)]} />
    )
}
export default PageHistory;